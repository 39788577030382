import React, { useEffect, useState, createContext } from 'react';
import { useSession } from 'next-auth/react';
import { Permission } from '../../types';
import Keycloak from './Keycloak';
import { ISession } from '@interfaces/session';

type PermissionCache = {
  [key: string]: boolean;
};

const redirectKey = 'sign_in_redirect';

type User = {
  email: string | null | undefined;
  name: string | null | undefined;
  token?: string | null | undefined;
};

interface IAuthProvider {
  initializing: boolean;
  user: User | null;
  // error: { message: string } | null
  setRedirect: (redirect: string) => void;
  getRedirect: () => string | null;
  clearRedirect: () => void;
  isAllowedTo: (permission: Permission) => Promise<boolean>;
  checkHasGroup: (group: string) => Promise<boolean>;
}

export const AuthContext = createContext({} as IAuthProvider);

AuthContext.displayName = 'AuthContext';

function setRedirect(redirect: string) {
  window.sessionStorage.setItem(redirectKey, redirect);
}

function getRedirect(): string | null {
  return window.sessionStorage.getItem(redirectKey);
}

function clearRedirect() {
  return window.sessionStorage.removeItem(redirectKey);
}

export function useAuth() {
  const auth = React.useContext(AuthContext);

  if (!auth) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return auth;
}

export function AuthProvider({ children }: { children: JSX.Element }) {
  const { data: session, status } = useSession() as unknown as ISession;

  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null>(session?.user);

  const isUser = !!session?.user;

  const cache: PermissionCache = {};

  const isAllowedTo = async (permission: Permission): Promise<boolean> => {
    const token = String(session?.accessToken);
    const _keycloak = new Keycloak(token);
    let isAllowed = false;
    if (isUser) {
      if (Object.keys(cache).includes(permission)) {
        return cache[permission];
      }
      isAllowed = await _keycloak.checkAccessRight(permission);
      cache[permission] = isAllowed;
    }
    return isAllowed;
  };

  const checkHasGroup = async (group: string) => {
    const userGroups = await getUserGroups();

    if (userGroups.includes(group)) {
      return true;
    }
    return false;
  };

  const getUserGroups = async () => {
    const token = String(session?.accessToken);
    const _keycloak = new Keycloak(token);
    return await _keycloak.getUserGroups();
  };

  useEffect(() => {
    if (status === 'authenticated') {
      setUser({
        email: session?.user?.email,
        name: session?.user?.name,
      });
    }
    if (status == 'unauthenticated') {
      setUser(null);
    }
    setInitializing(false);
  }, [session, isUser, status]);

  const value = {
    user,
    initializing,
    setRedirect,
    getRedirect,
    clearRedirect,
    isAllowedTo,
    checkHasGroup,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
